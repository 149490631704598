<template>
  <div>
    <StatisticCard
      @reload="getBlogger"
      v-if="blogger"
      :user="{ foreign: true, blogger_profile: blogger }"
    />
    <Loader v-else />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Loader from '@main/components/Loader.vue';
import StatisticCard from '@main/components/reusable/statistics/StatisticCard.vue';
import bloggers from '@main/api/bloggers';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';

export default {
  components: {
    StatisticCard,
    Loader,
  },
  props: {
    instaname: String,
    access: String,
  },
  computed: {
    ...mapState(['currentUser']),
  },
  methods: {
    ...mapActions('notifications', ['setWindow']),
    ...mapActions('bloggers', ['getBlogger']),
  },
  data: () => ({
    blogger: null,
  }),
  metaInfo: {
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Посмотрите охваты, цены, пол, возраст, страны, города, отзывы на блогеров',
      },
    ],
  },
  async mounted() {
    if (
      this.currentUser &&
      this.currentUser.blogger_profile &&
      this.currentUser.blogger_profile.instaname.toLowerCase() === this.instaname.toLowerCase()
    ) {
      this.$router.push('/mystat');
      return;
    }
    const blogger = await this.getBlogger({
      instaname: this.instaname,
      access: this.access,
      errBack: () => {
        this.$router.push({ name: 'reviews' });
      },
    });
    this.blogger = blogger;
  },
};
</script>
